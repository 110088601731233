import {useCallback, useEffect, useRef, useState} from "react";
import {
    matchPath,
    useLocation,
    unstable_useBlocker as useBlocker,
    unstable_BlockerFunction as BlockerFunction,
} from "react-router-dom";
import {useIntl} from "react-intl";
import Helmet from "react-helmet";
import styled from "styled-components";
import {closeSnackbar, enqueueSnackbar, useSnackbar} from "notistack";

import Layout from "../../components/common/Layout";
import Button from "../../components/styled/buttons/Button";
import LoadingContainer from "../../components/styled/LoadingContainer";
import LoadingSpinner from "../../components/styled/LoadingSpinner";
import Section from "../../components/styled/sections/Section";
import SectionContent from "../../components/styled/sections/SectionContent";
import FooterButtonContainer from "../../components/styled/buttons/FooterButtonContainer";
import CurrencySelectorDialog from "../../components/common/dialog/CurrencySelectorDialog";
import FutureOrderSection from "../../components/order/FutureOrderFormFields";
import SourceFormField from "../../components/order/SourceFormField";
import TargetFormFields from "../../components/order/TargetFormFields";

import {CurrencyType, FXOrderFormType, FXType} from "../../types/fx.type";
import {
    APP_NAVIGATE_DIRECTION,
    APP_NAVIGATE_TRANSITION_DURATION,
    APP_PATH,
} from "../../constants/app.constant";
import {useAppDispatch, useAppSelector} from "../../utils/store.utils";
import {
    clearPasswordTimeoutInterval, clearRsaTimeoutInterval,
    emitErrorToApp,
    navigateToApp,
} from "../../utils/integration.utils";
import {selectApp, selectFoMaxActiveCount, setIsWentBackAndToggledFalse} from "../../slice/app";
import {selectUser, updateUserAccountInformation, updateUserTier} from "../../slice/user";
import {
    selectCurrencies,
    selectFXList,
    selectFXOrderForm,
    updateFXTradeForm,
    updateFXOrderFormFX,
    updateFXOrderFormSelectingCurrencyType,
    updateFXOrderFormFee,
    resetFXOrderForm,
} from "../../slice/fx";
import {
    formatCurrencies,
    formatFXObjectList,
} from "../../formatters/fx/api/response";
import useNavigateThrottle from "../../hooks/useNavigateThrottle";
import {
    DEFAULT_FX_ORDER_FORM_FIELD,
    FX_TRADE_CURRENCY_TYPE, SHOW_FUTURE_SESSION,
} from "../../constants/fx.constant";
import {ALLOWED_RECEIVING_ROLE, INPUT_FIELD_HELPER_TEXT_TYPE} from "../../constants/common.constant";
import userAPI from "../../api/user.api";
import fxAPI from "../../api/fx.api";
import {getURLQueryValueByKey} from "../../utils/common.utils";
import {updateAPIAxiosInstanceToken} from "../../utils/api.utils";
import {formatBankAccount} from "../../formatters/user/api/response";
import {formatURL} from "../../formatters/common";
import {
    findExchangeableCurrenciesInFXList,
    findFXByCurrenciesText,
} from "../../formatters/fx";
import {getFeeCurrency, validateAmount} from "../../formatters/fx/order";
import useFXCalculator from "../../hooks/useFXCalculator";
import {UserBankAccountType, UserGetAccountsAPIResponseType} from "../../types/user.type";
import {isWentBackAndToggledFalseEnum} from "../../types/app.type";
import {isComeBackFromLatterPage} from "./services/isComeBackFromLatterPage";
import {
    ACCOUNT_FAIL_OR_DATA_IS_INVALID,
    fxlist_OR_ccylist_IS_FALSY_OR_EMPTY, RATE_DATA_ASK_BID_DP_SYMBOL_NULL,
    RATE_DATA_IS_EMPTY_OR_INVALID, USER_DATA_TYPE_COUNTRY_BASECURRECY_FROMCURRENCY_MISSING
} from "../../constants/errorMsg.constant";
import {isArray} from "../../utils/verifyType.utils";
import {processApiError} from "../../utils/errorHandling.utils";
import {ErrorObjForToastBar} from "../../types/errorObjType.type";
import {fetchRate} from "./services/fetchRate";
import {IntegrationMessageDataType} from "../../types/integration.type";
import {MAE_APP_RESPONSE_EVENT_TYPE} from "../../constants/integration.constant";
import {AxiosResponse} from "axios";
import {countActiveFO} from "./services/countActiveFO";
import {isCurrencyInArray} from "./services/isCurrecnyInArray";

const deepEqual = require("deep-equal");

const FXOrderFormSection = styled(Section)`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow-y: auto;
`;

const OrderFormPage = () => {
    const navigate = useNavigateThrottle();
    const location = useLocation();
    const intl = useIntl();
    const {enqueueSnackbar} = useSnackbar();
    const app = useAppSelector(selectApp);
    const user = useAppSelector(selectUser);
    const currencies = useAppSelector(selectCurrencies);
    const fxList = useAppSelector(selectFXList);
    const fxOrderForm = useAppSelector(selectFXOrderForm);
    const dispatch = useAppDispatch();
    const [isInitializing, setIsInitializing] = useState<boolean>(
        app.navigateDirection !== APP_NAVIGATE_DIRECTION.PREVIOUS
    );
    const isInitialized = useRef<boolean>(false);
    const sourceCurrencyInputRef = useRef<HTMLInputElement>(null);
    const targetCurrencyInputRef = useRef<HTMLInputElement>(null);
    const foMaxActiveCount = useAppSelector(selectFoMaxActiveCount)
    const {
        availableCurrencies,
        focusingCurrencySelector,
        focusingInput,
        setFocusingInput,
        isFooterButtonContainerShow,
        setIsFooterButtonContainerShow,
        isNumPadOpen,
        setIsNumPadOpen,
        isCurrencySelectorDialogOpen,
        handleCurrencySelectorOpen,
        handleCurrencySelectorClose,
        handleNumPadInputClick,
        updateFocusingInputAmount,
        handleExchange,
    } = useFXCalculator({
        form: "order",
        sourceInputElement: sourceCurrencyInputRef.current,
        targetInputElement: targetCurrencyInputRef.current,
    });

    const shouldBlock = useCallback<BlockerFunction>(
        ({currentLocation, nextLocation}) => {
            // WARNING: the currenct location must be checked, otherwise this blocker will be triggered in other next page if navigating too fast by user click button quickly
            if (
                matchPath({path: APP_PATH.fx.order.form}, currentLocation.pathname) &&
                !matchPath({path: APP_PATH.fx.order.account}, nextLocation.pathname)
            ) {
                // Reset FX order form with delay
                setTimeout(
                    () => dispatch(resetFXOrderForm()),
                    APP_NAVIGATE_TRANSITION_DURATION
                );
            }
            return false;
        },
        []
    );
    useBlocker(shouldBlock);

    useEffect(() => {
        if (app.navigateDirection !== APP_NAVIGATE_DIRECTION.PREVIOUS) init(); // preventing call when back to this page
    }, []);

    useEffect(() => {
        handleExchange(fxOrderForm.fx);
    }, [focusingInput, fxOrderForm.fx]);

    useEffect(() => {
        if (isInitialized.current) {
            let updatedFXOrderForm: FXOrderFormType = JSON.parse(
                JSON.stringify(fxOrderForm)
            );
            const sourceAmountErrors = validateAmount(
                fxOrderForm.source.amount.value,
                fxOrderForm.source.currency,
                currencies,
                fxOrderForm.future,
                {isContainMin: true, isContainMax: true}
            );

            if (sourceAmountErrors.length > 0) {
                // console.log('updating updatedFXOrderForm', updatedFXOrderForm)
                updatedFXOrderForm.source.amount.helper = {
                    type: INPUT_FIELD_HELPER_TEXT_TYPE.ERROR,
                    text: sourceAmountErrors[0].message,
                };
            } else {
                delete updatedFXOrderForm.source.amount.helper;
            }

            if (fxOrderForm.target.currency) {
                const targetAmountErrors = validateAmount(
                    fxOrderForm.target.amount.value,
                    fxOrderForm.target.currency,
                    currencies,
                    fxOrderForm.future
                );
                if (targetAmountErrors.length > 0) {
                    updatedFXOrderForm.target.amount.helper = {
                        type: INPUT_FIELD_HELPER_TEXT_TYPE.ERROR,
                        text: targetAmountErrors[0].message,
                    };
                } else {
                    delete updatedFXOrderForm.target.amount.helper;
                }
            }
            // console.log(fxOrderForm)
            dispatch(updateFXTradeForm({form: "order", data: updatedFXOrderForm}));
            // console.log('updateFXTradeForm  from form.tsx 1')

        }
    }, [fxOrderForm.source.amount.value, fxOrderForm.target.amount.value, fxOrderForm.future]);
    //todo this is deleted fxOrderForm.future is deleted,

    useEffect(() => {
        if (
            isInitialized.current === true ||
            app.navigateDirection === APP_NAVIGATE_DIRECTION.PREVIOUS
        ) {
            if (fxOrderForm.target.currency) {
                const feeCurrencySymbol = getFeeCurrency(
                    fxOrderForm.source.currency,
                    fxOrderForm.target.currency
                );
                const feeCurrency = currencies.find(
                    (v) => v.currency === feeCurrencySymbol
                );
                let updatedFXOrderFormFee = fxOrderForm.fee;
                if (feeCurrency) {
                    updatedFXOrderFormFee = {
                        currency: feeCurrency.currency,
                        conversion: fxOrderForm.future
                            ? feeCurrency.config.futureOrder.fee.conversion
                            : feeCurrency.config.marketOrder.fee.conversion,
                    };
                }

                if (!deepEqual(fxOrderForm.fee, updatedFXOrderFormFee))
                    dispatch(updateFXOrderFormFee(updatedFXOrderFormFee));
            }

            fetchRate(fxOrderForm);
        }
    }, [fxOrderForm.source.currency, fxOrderForm.target.currency]);

    useEffect(() => {
        if (isInitialized.current) {
        } else isInitialized.current = true;
    }, [fxOrderForm]);

    /**
     * Initializes the application.
     * Retrieves query values from the URL, updates the FX order form, fetches accounts, and performs entry point handling.
     * @async
     */
    const init = async () => {
        const renewedToken = getURLQueryValueByKey("token");
        const fromCurrency = getURLQueryValueByKey("from");
        const toCurrency = getURLQueryValueByKey("to");
        const historyIndex = app.history.findIndex((v) =>
            matchPath({path: v.path}, APP_PATH.watchlist.index) ||
            v.path.includes(APP_PATH.fx.order.details)
        );
        let existingFX: FXType | undefined;
        let updatedFXOrderForm: FXOrderFormType = JSON.parse(
            JSON.stringify(
                app.navigateDirection === APP_NAVIGATE_DIRECTION.PREVIOUS
                    ? fxOrderForm
                    : DEFAULT_FX_ORDER_FORM_FIELD
            )
        );
        updatedFXOrderForm.source.currency = user.baseCurrency; // User base currency as default
        const accounts = await fetchAccounts();

        // Entry point handling
        if (historyIndex < 0) {
            // Navigate from MAE app (Dashboard)
            if (!renewedToken) {
                emitErrorToApp();
                //navigateToApp();
                return;
            }
            updateAPIAxiosInstanceToken(renewedToken);

            if (user.fromCurrency) {
                const currency = currencies.find(
                    (v) => v.currency === user.fromCurrency
                );
                // Validate fromCurrency and valid account with sufficient account balance
                if (
                    currency &&
                    accounts.length > 0 &&
                    accounts.findIndex(
                        (v) => v.currency === currency.currency && v.balance > 0
                    ) > -1
                ) {
                    updatedFXOrderForm.source.currency = currency.currency;
                    dispatch(
                        updateFXTradeForm({form: "order", data: updatedFXOrderForm})
                    );
                    // console.log('updateFXTradeForm  from form.tsx 2')
                }
            }
        } else {
            // Navigate from Watchlist

            if (fromCurrency && toCurrency) {
                // console.log("fromCurrency", fromCurrency)
                // console.log("toCurrency", toCurrency)
                existingFX = findFXByCurrenciesText(fxList, [fromCurrency, toCurrency]); // Find the FX symbol in tradable FX list
                // Validate FX and valid account with sufficient account balance
                if (
                    existingFX
                    && accounts.length > 0
                    && accounts.findIndex(
                        (v) => v.currency === fromCurrency && v.balance > 0
                    ) > -1
                ) {
                    updatedFXOrderForm.source.currency = fromCurrency;
                    updatedFXOrderForm.target.currency = toCurrency;
                    dispatch(
                        updateFXTradeForm({form: "order", data: updatedFXOrderForm})
                    );
                    // console.log('updateFXTradeForm  from form.tsx 3')
                }
            }
        }
        setIsInitializing(false);
    };

    /**
     * Asynchronously fetches user bank account information from the user API.
     * Dispatches actions to update the user account information in the Redux store.
     * Handles error cases and navigation.
     *
     * @returns {Promise<UserBankAccountType[]>} - A promise that resolves to an array of user bank account objects.
     *
     * @throws {ErrorObjForToastBar} - If there is an error retrieving the account data or the data is invalid.
     *
     */
    const fetchAccounts = async (): Promise<UserBankAccountType[]> => {
        try {
            const response: AxiosResponse<UserGetAccountsAPIResponseType> = await userAPI.getAccounts();
            if (
                !response.data ||
                response.data.responseCode !== "0" ||
                !response.data.data
            ) throw {
                errorMsg: ACCOUNT_FAIL_OR_DATA_IS_INVALID,
                responseHttpStatus: response.status,
                responseData: response.data
            } as ErrorObjForToastBar
            if (
                !response.data.data.ccylist ||
                response.data.data.ccylist.length === 0
            ) throw {
                errorMsg: fxlist_OR_ccylist_IS_FALSY_OR_EMPTY,
                responseHttpStatus: response.status,
                responseData: response.data
            } as ErrorObjForToastBar

            dispatch(
                updateUserAccountInformation({
                    availableCurrencies: formatCurrencies(response.data.data.ccylist),
                    accounts: formatBankAccount(response.data.data.account),
                })
            );
            dispatch(updateUserTier(response.data.data.tier))
            return formatBankAccount(response.data.data.account);
        } catch (error) {
            processApiError(error, emitErrorToApp, undefined, true) //this is fatal
            return [];
        }
    };

    /**
     * Fetches the FX rate from the server and updates the FX order form.
     * @async
     * @function fetchRate
     * @returns {void}
     */

    // const fetchRate = async () => {
    //     if (!fxOrderForm.source.currency || !fxOrderForm.target.currency) return;
    //     const fx = fxList.find(
    //         (v) =>
    //             v.symbol ===
    //             `${fxOrderForm.source.currency}${fxOrderForm.target.currency}` ||
    //             v.symbol ===
    //             `${fxOrderForm.target.currency}${fxOrderForm.source.currency}`
    //     );
    //     if (!fx) return;
    //     try {
    //         const response = await fxAPI.getRate([fx.symbol]);
    //         if (
    //             !response.data.data ||
    //             response.data.data.length === 0 ||
    //             !isArray(response.data.data)
    //         ) throw {
    //             errorMsg: RATE_DATA_IS_EMPTY_OR_INVALID,
    //             responseHttpStatus: response.status,
    //             responseData: response.data
    //         } as ErrorObjForToastBar
    //         if ( //Explanation: .ask and .bid has been found to be null before, so I added this check
    //             !response.data.data[0].ask
    //             ||!response.data.data[0].bid
    //             || !response.data.data[0].symbol
    //             // || !response.data.data[0].dp
    //         ) throw {
    //             errorMsg: RATE_DATA_ASK_BID_DP_SYMBOL_NULL,
    //             responseHttpStatus: response.status,
    //             responseData: response.data
    //         } as ErrorObjForToastBar
    //         const fxObject = formatFXObjectList(response.data.data)[0];
    //         dispatch(updateFXOrderFormFX(fxObject));
    //         //handleExchange(fxObject);
    //     } catch (error) {
    //         processApiError(error, undefined, "FX rate is currently unavailable. Please try again later") //this is non-FATAL
    //     }
    // };

    /**
     * Update the state when the source input reference changes
     * @param {Element} sourceInputElement - The DOM element of the source input
     * @returns {void}
     */
    const handleSourceInputRefChange = (sourceInputElement: Element) => {
        setFocusingInput(sourceInputElement);
        dispatch(
            updateFXOrderFormSelectingCurrencyType(FX_TRADE_CURRENCY_TYPE.SOURCE)
        );
    };

    /**
     * Updates the target input element and dispatches an action to update the FX order form selecting currency type.
     *
     * @param {Element} targetInputElement - The target input element to update.
     * @return {void}
     */
    const handleTargetInputRefChange = (targetInputElement: Element) => {
        setFocusingInput(targetInputElement);
        dispatch(
            updateFXOrderFormSelectingCurrencyType(FX_TRADE_CURRENCY_TYPE.TARGET)
        );
    };

    /**
     * Sets the focusing input element to the provided rateInputElement.
     *
     * @param {Element} rateInputElement - The input element to set as the focusing input.
     */
    const handleRateInputRefChange = (rateInputElement: Element) => {
        setFocusingInput(rateInputElement);
    };

    /**
     * Handles the click event of a currency select button.
     *
     * @param {FX_TRADE_CURRENCY_TYPE} currencyType - The type of currency: SOURCE or TARGET.
     */
    const handleCurrencySelectClick = (currencyType: FX_TRADE_CURRENCY_TYPE) => {
        handleCurrencySelectorOpen(
            currencyType,
            currencyType === FX_TRADE_CURRENCY_TYPE.SOURCE
                ? user.availableCurrencies
                : findExchangeableCurrenciesInFXList(
                    fxList,
                    currencies,
                    fxOrderForm.source.currency
                )
        );
    };

    /**
     * Updates the currency in the FXOrderForm.
     *
     * @param {CurrencyType} updatedCurrency - The updated currency.
     */
    const handleEditCurrency = (updatedCurrency: CurrencyType) => {
        let updatedFXOrderForm: FXOrderFormType = JSON.parse(
            JSON.stringify(fxOrderForm)
        );
        if (!updatedFXOrderForm[focusingCurrencySelector]) return;

        // Handle user selected a currency that is same as opposite currency
        if (focusingCurrencySelector === FX_TRADE_CURRENCY_TYPE.SOURCE) {
            if (
                updatedCurrency.currency === fxOrderForm.target.currency ||
                findExchangeableCurrenciesInFXList(
                    fxList,
                    currencies,
                    updatedCurrency.currency
                ).length < 1 ||
                !isCurrencyInArray(
                    fxOrderForm.target.currency!,
                    findExchangeableCurrenciesInFXList(fxList, currencies, updatedCurrency.currency)
                )
            ) {
                //
                // Swap currency and amount
                /*updatedFXOrderForm.target = {
                    currency: fxOrderForm.source.currency,
                    amount: fxOrderForm.source.amount,
                };
                updatedFXOrderForm.source.amount = fxOrderForm.target.amount;*/

                // Reset target currency
                updatedFXOrderForm.target = DEFAULT_FX_ORDER_FORM_FIELD.target;
                updatedFXOrderForm.future = false
            }
        } else if (
            focusingCurrencySelector === FX_TRADE_CURRENCY_TYPE.TARGET &&
            updatedCurrency.currency === fxOrderForm.source.currency &&
            fxOrderForm.target.currency
        ) {
            // IT SHOULD NOT HAPPEN SINCE TARGET CURRENCY IS PAIR OF SOURCE CURRENCY
            // Swap currency and amount
            /*updatedFXOrderForm.source = {
                currency: fxOrderForm.target.currency,
                amount: fxOrderForm.target.amount,
            };
            updatedFXOrderForm.target.amount = fxOrderForm.source.amount;*/
        }

        updatedFXOrderForm[focusingCurrencySelector].currency =
            updatedCurrency.currency;
        // Handle user select the same currency, refresh the FX rate
        if (
            fxOrderForm.fx &&
            fxOrderForm[focusingCurrencySelector].currency &&
            fxOrderForm.fx.currencies.indexOf(updatedCurrency.currency) > -1 &&
            fxOrderForm.fx.currencies.indexOf(
                fxOrderForm[focusingCurrencySelector].currency!
            ) > -1
        ) {
            fetchRate(fxOrderForm);
        } else {
            delete updatedFXOrderForm.fx;
        }
        handleExchange();
        dispatch(updateFXTradeForm({form: "order", data: updatedFXOrderForm}));

        if (isComeBackFromLatterPage(app.backFromPath.path)) {
            dispatch(
                setIsWentBackAndToggledFalse(isWentBackAndToggledFalseEnum.WENT_BACK_AND_TOGGLE_FALSE)
            )
        }

        // console.log('updateFXTradeForm  from form.tsx 4')
    };

    /**
     * Handles the click event of a number pad key.
     * @param {("add" | "remove")} type - The type of operation to perform ("add" or "remove").
     * @param {number} [num] - The number value associated with the key. Optional, only required for "add" type.
     * @returns {void}
     */
    const handleNumPadKeyClick = (type: "add" | "remove") => (num?: number) => {
        updateFocusingInputAmount(type, num);
        handleExchange(fxOrderForm.fx);
    };

    /**
     * Checks if the form is able to proceed to the next step.
     * Returns true if the form passes all the validation checks, otherwise returns false.
     *
     * @returns {boolean} - True if the form is able to proceed, otherwise false.
     */
    const ableToNext = (): boolean => {
        // Form validation
        const sourceCurrency = currencies.find(
            (v) => v.currency === fxOrderForm.source.currency
        );
        const targetCurrency = currencies.find(
            (v) => v.currency === fxOrderForm.target.currency
        );
        if (!sourceCurrency || !targetCurrency) return false;

        // Check source currency amount min & max
        const sourceAmountErrors = validateAmount(
            fxOrderForm.source.amount.value,
            sourceCurrency.currency,
            currencies,
            fxOrderForm.future,
            {isContainMin: true, isContainMax: true}
        );
        const targetAmountErrors = validateAmount(
            fxOrderForm.target.amount.value,
            targetCurrency.currency,
            currencies,
            fxOrderForm.future
        );
        if (sourceAmountErrors.length > 0 || targetAmountErrors.length > 0)
            return false;

        return true;
    };

    /**
     * Executes the logic when the next button is clicked in the order form step.
     * Checks if it is able to proceed to the next step, validates the account balance, and navigates to the next step.
     */
    const handleNextClick = async () => {
        if (!ableToNext()) return;

        // Account balance validation
        const sourceCurrencyAccounts = user.accounts.filter(
            (v) => v.currency === fxOrderForm.source.currency
        );
        const targetCurrencyAccounts = user.accounts.filter(
            (v) => (v.currency === fxOrderForm.target.currency) &&
                ((ALLOWED_RECEIVING_ROLE as string[]).includes(v.type))
        )
        if (
            sourceCurrencyAccounts.length < 1 ||
            targetCurrencyAccounts.length < 1
        ) {
            enqueueSnackbar(
                intl.formatMessage({
                    id: "app.page.orderForm.snackbar.submit.message.error.noAccount",
                }),
                {variant: "general", mode: "info"}
            );
            return;
        }

        if (fxOrderForm.future) {
            try {
                const currentActiveFO = await countActiveFO()
                if(currentActiveFO){
                    if (currentActiveFO >= foMaxActiveCount) {
                        enqueueSnackbar(
                            intl.formatMessage({
                                id: "app.page.futureOrder.toastbar.reachedMaxNumFO",
                            }),
                            {variant: "general", mode: "info"}
                        );
                        return;
                    }
                }
            } catch(error) {
                processApiError(error)
                return
            }
        }

        dispatch(
            setIsWentBackAndToggledFalse(isWentBackAndToggledFalseEnum.MAY_GO_BACK_AGAIN_BUT_HAVE_NOT_TOGGLE)
        )

        navigate(formatURL(`/order/form/step-2`));
    };

    return (
        <Layout
            title={intl.formatMessage({id: "app.page.orderForm.header.title"})}
            numPadProps={{
                open: isNumPadOpen,
                onNumberKeyClick: handleNumPadKeyClick("add"),
                onBackspaceKeyClick: handleNumPadKeyClick("remove"),
                onClose: () => {
                    setIsNumPadOpen(false);
                },
                onExited: () => {
                    setIsFooterButtonContainerShow(true);
                },
            }}
        >
            <Helmet>
                <title>
                    {intl.formatMessage({
                        id: "app.page.orderForm.header.title",
                    })}
                </title>
            </Helmet>
            {isInitializing ? (
                <LoadingContainer>
                    <LoadingSpinner display="block" $centered/>
                </LoadingContainer>
            ) : (
                <>
                    <FXOrderFormSection>
                        <SectionContent hasPaddingX>
                            <SourceFormField
                                ref={sourceCurrencyInputRef}
                                onCurrencyClick={handleCurrencySelectClick}
                                onAmountClick={handleNumPadInputClick(
                                    sourceCurrencyInputRef.current
                                )}
                                onSourceInputRefChange={handleSourceInputRefChange}
                            />
                            <TargetFormFields
                                ref={targetCurrencyInputRef}
                                onCurrencyClick={handleCurrencySelectClick}
                                onAmountClick={handleNumPadInputClick(
                                    targetCurrencyInputRef.current
                                )}
                                onTargetInputRefChange={handleTargetInputRefChange}
                            />
                            {
                                SHOW_FUTURE_SESSION && fxOrderForm.target.currency &&
                                <FutureOrderSection
                                    onSetIsNumPadOpen={setIsNumPadOpen}
                                    onRateInputRefChange={handleRateInputRefChange}
                                />
                            }


                        </SectionContent>
                    </FXOrderFormSection>
                    {isFooterButtonContainerShow && (
                        <FooterButtonContainer>
                            <Button
                                id={"next-button-of-step-1"}
                                theme="primary"
                                size="lg"
                                disabled={!ableToNext()}
                                onClick={handleNextClick}
                            >
                                {intl.formatMessage({
                                    id: "app.page.orderForm.form.order.button.next",
                                })}
                            </Button>
                        </FooterButtonContainer>
                    )}
                </>
            )}
            <CurrencySelectorDialog
                open={isCurrencySelectorDialogOpen}
                availableCurrencies={availableCurrencies}
                onClose={handleCurrencySelectorClose}
                onConfirm={handleEditCurrency}
            />
        </Layout>
    );
};

export default OrderFormPage;
